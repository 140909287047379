import {
    PRIVACY_POLICY_URL,
    TERMS_AND_CONDITIONS_URL,
} from 'components/util/routeConstants';

const ComplianceFootnote = () => {
    return (
        <>
            By signing up, you agree to the company’s
            <a target="_blank" href={TERMS_AND_CONDITIONS_URL} rel="noreferrer">
                {' '}
                Terms & Conditions{' '}
            </a>
            and
            <a target="_blank" href={PRIVACY_POLICY_URL} rel="noreferrer">
                {' '}
                Privacy Policy
            </a>
            .
        </>
    );
};

export {ComplianceFootnote};
