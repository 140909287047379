import HeadTitle from 'components/ui/page/HeadTitle';
import {Box, Button, Typography} from 'components/mui';
import {useSession, signIn as nextAuthSignIn} from 'next-auth/client';
import {useRouter} from 'next/router';
import {logger} from 'utils/logger';
import {AccountAccessPage} from 'components/ui/page/AccountAccessPage';
import {ComplianceFootnote} from 'components/ui/pages/sign-in/ComplianceFootnote';
import {Notification} from 'core/Notification/index';
import {LOGIN_URL} from 'components/util/routeConstants';
import {useLastKnownRouteStore} from 'components/providers/LastKnownRouteProvider';

export const PASSWORD_EXPIRED_ERROR_CODE = 'expired_password';
export const DEFAULT_LOGIN_ERROR_TEXT =
    'Something went wrong. Please try again.';
export const PASSWORD_EXPIRED_ERROR_CONTENT = (
    <>
        <span>
            Password has expired, please check your email for a reset password
            link.
        </span>
        <br />
        <br />
        <span>
            If you have already reset your password, try logging in again.
        </span>
    </>
);

type ErrorContent = string | JSX.Element;

const LoginPage = () => {
    const router = useRouter();
    const {query} = router;
    const [session, loading] = useSession();

    const {route: previousPath, setCurrentRoute} = useLastKnownRouteStore(
        (state) => state
    );

    if (loading) return null;

    if (session?.user?.userId) {
        Notification.clearMessages();

        const validPath = previousPath?.includes('login') ? '/' : previousPath;

        router
            .push(validPath || '/')
            .then(() => setCurrentRoute(validPath || '/', false))
            .catch((e) => {
                logger.error(e);
            });
        return;
    }

    if (query?.error) {
        let errorContent = DEFAULT_LOGIN_ERROR_TEXT as ErrorContent;
        if (query?.error === PASSWORD_EXPIRED_ERROR_CODE) {
            errorContent = PASSWORD_EXPIRED_ERROR_CONTENT;
        }
        return (
            <>
                <HeadTitle>Login</HeadTitle>
                <AccountAccessPage
                    pageId="login-error"
                    headerContent="Sales Development Platform"
                    footnoteContent={<ComplianceFootnote />}
                    bodyContent={
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-between"
                            gap={2}
                        >
                            <Typography color="error">
                                {errorContent}
                            </Typography>
                            <Box>
                                <Button href={LOGIN_URL}>Login</Button>
                            </Box>
                        </Box>
                    }
                />
            </>
        );
    }

    nextAuthSignIn('auth0').catch((e) => {
        logger.error(e);
    });

    return <HeadTitle>Login</HeadTitle>;
};

export default LoginPage;
