import PropTypes from 'prop-types';
import Image from 'next/legacy/image';
import styled from 'styled-components';
import {Grid, Box, Typography} from 'components/mui';
import {PublicPage} from 'components/ui/page/PublicPage';
import {Logo} from 'components/ui/graphics/Logo';
import {useSession} from 'next-auth/client';

const AccountAccessPageGrid = styled(Grid)`
    height: 100%;
    align-items: center;

    .AccountAccessImage {
        min-width: 20px;
        position: relative;
        ${({theme}) => theme.breakpoints.down('lg')} {
            left: -200px;
        }

        ${({theme}) => theme.breakpoints.down('xl')} {
            left: -100px;
        }
    }

    .AccountAccessPageColumn {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        &__image-area {
            display: none;
            ${({theme}) => theme.breakpoints.up('md')} {
                display: flex;
            }
        }

        &__content-area {
            padding: 0px ${({theme}) => theme.spacing(8)};
        }
    }
`;

const StyledHeader = styled(Typography)`
    font-size: 2.6rem;
    margin-top: ${({theme}) => theme.spacing(5)};
    margin-bottom: ${({theme}) => theme.spacing(1)};

    ${({theme}) => theme.breakpoints.up('sm')} {
        font-size: 3.2rem;
        margin-top: ${({theme}) => theme.spacing(14)};
        margin-bottom: ${({theme}) => theme.spacing(2)};
    }
`;

const StyledSubtitle = styled(Typography)`
    margin-bottom: ${({theme}) => theme.spacing(2)};

    ${({theme}) => theme.breakpoints.up('sm')} {
        margin-bottom: ${({theme}) => theme.spacing(5)};
    }

    &.MuiTypography-subtitle2 {
        font-size: 1.2rem;
        line-height: 2rem;
        ${({theme}) => theme.breakpoints.up('sm')} {
            font-size: 1.4rem;
            line-height: 2.4rem;
        }
    }
`;

const StyledFootnote = styled(Typography)`
    margin-top: ${({theme}) => theme.spacing(5)};

    ${({theme}) => theme.breakpoints.up('sm')} {
        margin-top: ${({theme}) => theme.spacing(14)};
    }

    a {
        font-weight: 600;
        color: inherit;
    }
`;

const AccountAccessPage = ({
    pageId,
    headerContent,
    subtitleContent,
    footnoteContent,
    bodyContent,
}) => {
    // the following code is a hacky way to force a rerender so the styles will load on pre-auth pages
    // it fixes a critical bug (ENG-2092) which I am not sure can be solved while we are using both SSR and styled-components
    const loading = useSession()[1];
    if (loading) return null;

    return (
        <PublicPage pageId={pageId}>
            <AccountAccessPageGrid height="100%" container direction="row">
                <Grid
                    className="AccountAccessPageColumn__image-area"
                    sm={12}
                    md={6}
                    item
                >
                    <Box className="AccountAccessImage">
                        <Image
                            src="/components/pages/sign-in/laptop.png"
                            height="957"
                            width="1075"
                            alt="screenshot of sales development platform on a laptop"
                        />
                    </Box>
                </Grid>
                <Grid
                    className="AccountAccessPageColumn__content-area"
                    sm={12}
                    md={6}
                    item
                >
                    <Box color="secondary.dark" maxWidth="525px">
                        <Logo />
                        <StyledHeader variant="h1" color="inherit">
                            {headerContent}
                        </StyledHeader>
                        <StyledSubtitle
                            variant="subtitle2"
                            color="inherit"
                            paragraph
                        >
                            {subtitleContent}
                        </StyledSubtitle>
                        {bodyContent}
                        <StyledFootnote variant="body2" color="inherit">
                            {footnoteContent}
                        </StyledFootnote>
                    </Box>
                </Grid>
            </AccountAccessPageGrid>
        </PublicPage>
    );
};
AccountAccessPage.propTypes = {
    pageId: PropTypes.string.isRequired,
    headerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subtitleContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    footnoteContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    bodyContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export {AccountAccessPage};
