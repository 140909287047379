import styled from 'styled-components';
import {NotificationsContext} from 'components/contexts/notifications';
import NotificationMessages from 'components/ui/notification/NotificationMessages';

const PageWrapper = styled.div`
    height: 100vh;
    background-color: ${({theme}) => theme.palette.background.default};

    .notifications-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        position: fixed;
        top: 100px;
        left: 0;
        z-index: 900;
    }
`;

interface PublicPageProps {
    pageId: string;
    children: React.ReactNode;
}

const PublicPage = ({pageId, children}: PublicPageProps) => {
    return (
        <PageWrapper data-testid={pageId}>
            <NotificationsContext.Consumer>
                {(notificationMessages) => {
                    if (
                        !notificationMessages ||
                        notificationMessages.length === 0
                    )
                        return null;
                    return (
                        <div className="notifications-wrapper">
                            <NotificationMessages
                                messages={notificationMessages}
                            />
                        </div>
                    );
                }}
            </NotificationsContext.Consumer>
            {children}
        </PageWrapper>
    );
};

export {PublicPage};
